@import "~primeng/resources/themes/md-dark-indigo/theme.css";

:root {
  --primary-color-additional: rgba(28, 28, 38, 0.9);
  --primary-color: rgba(47, 47, 69, 0.6);
  --primary-color-hovered: rgba(20, 20, 25, 0.92);
  --primary-color-opaque: rgb(17, 17, 21);
  --header-primary-color: rgba(12, 13, 15, 0.8);
  --basic-background: linear-gradient(to right, rgba(20, 20, 24, 0.01), var(--gray-800), var(--gray-900));
  --dark-background: rgb(17, 17, 21);

  --table-data-color: #fff;
  --text-color: var(--surface-900);
  --hovered-text-color: var(--surface-700);

  --submit-button-background-color: rgba(255, 152, 0, 1);
  --submit-button-hover-color: rgba(255, 152, 0, 0.8);
  --submit-button-color: black;

  --anim-layer1-color: rgba(48, 2, 122, 0.7);
  --anim-layer2-color: rgba(103, 5, 175, 1);
  --anim-layer3-color: rgba(61, 38, 175, 0.59);
  --anim-layer4-color: rgba(70, 6, 169, 0.49);
  --animation-background: rgb(70, 6, 169, 0.49);

}
